import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link as LinkScroll } from 'react-scroll';
import styled, { css } from 'styled-components';
import Link from 'gatsby-link';
import { AnalyticsContext, AnalyticsLocationProvider } from 'latitude-analytics';

import { Hero } from '@latitude/hero';
import Section from '@latitude/section';
import { Link as LLink } from '@latitude/link';
import { ALIGN, BREAKPOINT, COLOR, FONT_WEIGHT } from '@latitude/core/utils/constants';
import { Heading2, Heading4 } from '@latitude/heading';
import { Box } from '@latitude/box';
import { ImportantInformation } from '@latitude/important-information';

import { graphql } from 'gatsby';
import Layout from '../../../components/layout';
import ArticleCard from '@/components/ArticleCard/ArticleCard';
import Lframe from '@/components/Lframe/Lframe';
import FeeSavingsCalculator from '@/components/FeeSavingsCalculator/FeeSavingsCalculator';
import { Stepper } from '@/components/stepper';
import HeroBanner from '@/components/lab-components/HeroBanner';

import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';

import Text from '../../../components/Text/Text';
import DegreesCardMicrositeHeader from './_28d-microsite-header';
import benefit1Image from './images/icon-benefits-1.svg';
import benefit2Image from './images/icon-benefits-2.svg';
import benefit3Image from './images/icon-benefits-3.svg';

class TravelInspirationPage extends React.Component {
  static contextType = PageContext

  constructor(props) {
    super(props);
    this.articles = props.data.allMarkdownRemark.edges;
    this.categories = [
      {
        tags: 'adventure',
        title: 'Adventure'
      },
      {
        tags: 'culture',
        title: 'Culture'
      },
      {
        tags: 'relax',
        title: 'Relaxing'
      },
      {
        tags: 'shopping',
        title: 'Shopping'
      },
      {
        tags: 'tips',
        title: 'Travel Tips'
      }
    ];

    this.itemsToShowStep = 9;
    this.state = {
      tags: ['adventure', 'culture', 'relax', 'shopping', 'tips'],
      itemsToShow: this.itemsToShowStep,
      contentfulData: {}
    };
  }

  componentDidMount() {
    const tags = new URLSearchParams(this.props.location.search).get('filter');
    if (tags) {
      this.setState({
        ...this.state,
        tags: [...tags.split(',')]
      });
    }
  }

  getArticlesByTag(tag) {
    if (!this.articlesByTag) {
      this.articlesByTag = [];
      this.articlesByTag.all = [];
      this.articles.map((edge, i) => {
        const item = edge.node.frontmatter;
        item.travelTags.split(' ').map((itemTag, j) => {
          if (!this.articlesByTag[itemTag]) {
            this.articlesByTag[itemTag] = [];
          }
          this.articlesByTag[itemTag].push(item);
        });
        this.articlesByTag.all.push(item);
      });
    }

    return this.articlesByTag[tag];
  }

  getArticlesByTags(tags) {
    let articles = [];
    if (tags.length > 0) {
      tags.map((tag, i) => {
        const taggedArticles = this.getArticlesByTag(tag);
        articles = [...articles, ...taggedArticles];
      });
    } else {
      articles = this.articlesByTag
        ? this.articlesByTag['all']
        : this.getArticlesByTag('all');
    }
    return articles;
  }

  setSelectedTag(tag) {
    let currentTags = this.state.tags;

    if (tag === 'all') {
      currentTags = [];
    } else {
      if (currentTags.indexOf(tag) >= 0) {
        currentTags.splice(currentTags.indexOf(tag), 1);
      } else {
        currentTags.push(tag);
      }
    }

    this.setState({
      ...this.state,
      tags: currentTags,
      itemsToShow: this.itemsToShowStep
    });
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <Helmet>
          <title>
            Travel Inspiration | Latitude 28&deg; Global Platinum Mastercard |
            International Credit Card
          </title>
        </Helmet>

        <main
          className="navigation-spacer"
          css={`
            @media (min-width: ${BREAKPOINT.LG}) {
              margin-top: 100px !important;
            }
            .microsite-header {
              z-index: 2000 !important;
            }
          `}
        >
          {this.context?.updatedBody?.heroBannerData?.[0] ? (
            <HeroBanner {...this.context?.updatedBody?.heroBannerData[0]} />
          ) : (
            <HeroContainer>
              <Lframe travel="travel" />
              <StyledHero
                title="Need some Travel Inspiration?"
                text="Latitude 28° Global has got you covered. From shopping, food, to music festivals and long haul flights, you're sure to find inspiration for your next trip."
              />
            </HeroContainer>
          )}

          <DegreesCardMicrositeHeader
            data={{ ...this.context?.updatedBody?.secondaryNavData }}
          />
          <StyledSection id="sticky-cta-trigger">
            <ArticleCategoryFilterDesktop>
              <span css="margin-right: 20px;">Filter by category</span>
              <ButtonContainer>
                {this.categories.map((category, i) => {
                  const opts = {};
                  if (this.state.tags.indexOf(category.tags) !== -1) {
                    opts.active = true;
                  }
                  return (
                    <CategoryButton
                      {...opts}
                      key={i}
                      onClick={e => {
                        this.setSelectedTag(category.tags);
                      }}
                    >
                      {category.title}
                    </CategoryButton>
                  );
                })}
                {this.state.tags.length > 0 && (
                  <ClearCategoryButton
                    onClick={e => {
                      this.setSelectedTag('all');
                    }}
                  >
                    Clear filters
                  </ClearCategoryButton>
                )}
              </ButtonContainer>
            </ArticleCategoryFilterDesktop>
            <ArticleCategoryFilterMobile
              categories={this.categories}
              activeTags={this.state.tags}
              updateActiveTags={this.setSelectedTag.bind(this)}
            />

            <div className="row">
              {this.getArticlesByTags(this.state.tags).map((item, i) => {
                if (i < this.state.itemsToShow) {
                  return (
                    <div
                      key={i}
                      className="col-10 col-md-6 col-lg-4 offset-1 offset-md-0"
                      css="margin-bottom:30px;"
                    >
                      <StyledCard
                        heading={
                          <Link
                            to={`/credit-cards/28-degrees/travel-inspiration/${item.id}.html`}
                            className="article-link"
                          >
                            {item.title}
                          </Link>
                        }
                        className="mb-0 bg-white h-100"
                        thumbnail={require(`./images/travel-inspiration/thumbnails/${item.id}.jpg`)}
                      >
                        <p className="d-none d-md-block">{item.description}</p>
                      </StyledCard>
                    </div>
                  );
                }
              })}
            </div>
            {this.state.itemsToShow <
              this.getArticlesByTags(this.state.tags).length && (
              <div className="mt-3 mb-5 text-center">
                <LLink
                  button="tertiary"
                  onClick={e => {
                    const prevItemsToShow = this.state.itemsToShow;
                    this.setState({
                      ...this.state,
                      itemsToShow: prevItemsToShow + this.itemsToShowStep
                    });
                  }}
                  disableDefaultEventTracking
                  css="display:inline-block;"
                >
                  More inspiration
                </LLink>
              </div>
            )}
          </StyledSection>

          <Section
            id="benefits"
            css={`
              && {
                padding: 40px 0;
                background-color: #ffff;
                @media (min-width: ${BREAKPOINT.LG}) {
                  padding: 72px 0;
                  background-size: auto 80%;
                  background-position: left center, right center;
                }
              }

              .benefit-content-list {
                list-style-type: none;

                li:before {
                  content: '•';
                  position: absolute;
                  margin-left: -20px;
                  color: #63b8ff;
                  font-size: 35px;
                }

                li {
                  font-weight: 300;
                }
              }
            `}
          >
            <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
              Benefit at every stage of your trip
            </Heading4>
            <AnalyticsLocationProvider location="Benefit at every stage of your trip">
              <Stepper
                data={[
                  {
                    id: 'before-you-go',
                    title: 'Before you go',
                    titleHTML: `<span>1. Before you go</span><img src="${benefit1Image}" alt=""/>`,
                    content: (
                      <ul className="benefit-content-list">
                        <li key="0">
                          Book your flights, accommodation and adventures on
                          Latitude 28&deg; Global and avoid the international
                          transaction and currency conversion fees
                        </li>
                        <li key="1">
                          Buy what you need for your trip up to 55 days interest
                          free
                          <sup>
                            <LinkScroll
                              to="note-hash"
                              href="#note-hash"
                              spy
                              smooth
                              duration={500}
                              offset={-140}
                              className="link-noline"
                            >
                              #
                            </LinkScroll>
                          </sup>
                        </li>
                        <li key="2">
                          <Link
                            className="link-underline-blue"
                            href="/credit-cards/28-degrees/flight-delay-pass/"
                          >
                            Register
                          </Link>{' '}
                          your Latitude 28&deg; Global when you book your flight
                          to take advantage of our Flight Delay Pass
                          <sup>
                            <LinkScroll
                              to="note-1"
                              spy
                              smooth
                              duration={500}
                              offset={-170}
                              href="#note-1"
                              className="link-noline"
                            >
                              1
                            </LinkScroll>
                          </sup>
                        </li>
                        <li key="3">
                          Check out our{' '}
                          <Link
                            target="_blank"
                            rel="noopener noreferrer"
                            className="link-underline-blue"
                            href="/credit-cards/28-degrees/travel-inspiration"
                          >
                            blog
                          </Link>{' '}
                          for travel inspiration and other handy tips before you
                          jet off!
                        </li>
                      </ul>
                    )
                  },
                  {
                    id: 'on-the-go',
                    title: `On the go`,
                    titleHTML: `<span>2. On the go</span>
                              <img src="${benefit2Image}" alt=""/>`,
                    content: (
                      <ul className="benefit-content-list">
                        <li key="0">
                          No overseas fees on purchases when travelling
                        </li>
                        <li key="1">
                          Enjoy 3GB of free global data roaming for 15 days with
                          access to Flexiroam’s global data network of over 580
                          telcos in 150+ countries. You also get 15% discount on
                          any extra data purchased with your 28° Mastercard
                          credit card
                          <sup>
                            <LinkScroll
                              to="note-2"
                              spy
                              smooth
                              duration={500}
                              offset={-170}
                              href="#promo-wifi"
                            >
                              2
                            </LinkScroll>
                          </sup>
                        </li>
                        <li key="3">
                          <a
                            className="link-underline-blue"
                            target="_blank"
                            href="https://specials.priceless.com/en-au/cardhome/28%C2%B0_Global_Platinum_Mastercard?issuerId=201810190038&productId=201904150013"
                          >
                            Priceless Specials
                          </a>{' '}
                          exclusive to Mastercard holders
                        </li>
                        <li key="4">Pay Any Way</li>
                      </ul>
                    )
                  },
                  {
                    id: 'once-you-return',
                    title: `Once you return`,
                    titleHTML: `<span>3. Once you return</span>
                              <div class="benefits-icon"><img src="${benefit3Image}" alt=""/></div>`,
                    content: (
                      <ul className="benefit-content-list">
                        <li>
                          Keep the holiday vibes going, shop at your favourite
                          international brands online and avoid international
                          transaction fees on purchases
                        </li>
                        <li>
                          Our Pay Any Way options mean you can make fast
                          payments everyday with your phone or smartwatch
                        </li>
                        <li>
                          Enjoy up to 55 days interest free on purchases
                          <sup>
                            <LinkScroll
                              to="note-hash"
                              spy
                              smooth
                              duration={500}
                              offset={-140}
                              href="#note-hash"
                              className="link-noline"
                            >
                              #
                            </LinkScroll>
                          </sup>
                        </li>
                      </ul>
                    )
                  }
                ]}
              />
            </AnalyticsLocationProvider>
          </Section>

          <Section
            css={`
            && {
              background-color: #F8F8F8
              padding: 48px 0;
              @media (min-width: ${BREAKPOINT.LG}) {
                padding: 72px 0;
              }
            }
          `}
            id="calculate"
          >
            <div css="max-width:880px; margin:0 auto;">
              <Heading2
                align={ALIGN.CENTER}
                color={COLOR.BLACK}
                css={`
                  font-size: 24px;
                  line-height: 36px;
                  margin-bottom: 16px;
                  @media (min-width: ${BREAKPOINT.LG}) {
                    font-size: 36px;
                    line-height: 48px;
                  }
                `}
              >
                Calculate your savings
              </Heading2>
              <Text align={ALIGN.CENTER} margin="0 0 0 32px">
                See how much you could save on international transaction fees
                when using Latitude 28&deg; Global
              </Text>
              <Box
                margin="32px 0 0"
                css={`
                  .fee-savings-calculator .tile--feedback {
                    background-color: #006aff;
                  }
                  .fee-savings-calculator
                    #rangeSlider
                    .range-slider.slider
                    .range-slider-inner
                    .range-slider-fill {
                    background-color: #006aff;
                  }
                  .fee-savings-calculator #rangeSlider .range-slider-value {
                    background-color: #006aff;
                  }
                `}
              >
                <AnalyticsContext.Consumer>
                  {([analytics]) => (
                    <FeeSavingsCalculator analytics={analytics} isBasic />
                  )}
                </AnalyticsContext.Consumer>
              </Box>
            </div>
          </Section>

          <ImportantInformation
            id="important-Info"
            data={{
              content: {
                importantInformationSectionOne: [
                  '# To take advantage of up to 55 days Interest Free on everyday credit card purchases, you need to pay the full closing balance on each statement of account by the applicable due date.',
                  '<sup id="note-star">*</sup>Savings will depend on individual circumstances. The estimated saving is not an actual saving that you have achieved. It is a calculation based on no international transaction fees on purchase and no currency conversion fees being charged by Latitude 28° Global Platinum Mastercard, and comparing other lenders\' common charge of 3%. It assumes that minimum monthly repayments have been made when due. Certain icons from The Noun Project.'
                ],
                importantInformationSectionTwo: [
                  '<sup id="note-1">1</sup> The Flight Delay Pass gives free access to select airport lounges, alternatively cardholders can use their Flight Delay Pass to offset a dollar amount off the total bill at select dining options. This will vary based on location and affiliated LoungeKey Partners at the time. Please note that not all airports offer Flight Delay Pass and the selected airports may be restricted to certain terminals. Access to and use of the Flight Delay Pass is subject to the Terms of Use which are subject to change. Further details of the list of lounges that cardholders are eligible to visit and the specific conditions applicable to each lounge access (Conditions of Use) may also be accessed in the Terms of Use. Participating lounges and their Conditions of Use are subject to change.',
                  '<sup id="note-2">2</sup> Terms and conditions apply. See the <a href="/credit-cards/28-degrees/global-data-roaming/">Latitude dedicated page</a> or <a href="/credit-cards/28-degrees/faqs/">FAQs</a> to learn more.'
                ]
              }
            }}
            sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
            sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
            {...this.context?.updatedBody?.importantInfoData?.[0]}
          />
        </main>
      </Layout>
    );
  }
}

const HeroContainer = styled.div`
  position: relative;
`;

const StyledLframe = styled(Lframe)`
  background-image: url(${require('./images/lframe-inspiration.png')});
  @media (min-width: ${BREAKPOINT.XL}) {
    background-position: 60vw 0;
  }
`;

const StyledHero = styled(Hero)`
  .HeroContent {
    background-color: ${COLOR.WHITE};
    margin-bottom: -24px;
    @media (min-width: ${BREAKPOINT.MD}) {
      background-color: transparent;
      min-height: 380px;
      margin-left: 36px;
      margin-bottom: inherit;
    }
    @media (min-width: ${BREAKPOINT.LG}) {
      margin-left: 0;
    }
  }
`;

const StyledSection = styled(Section)`
  background-color: #F8F8F8;

  @media (max-width: ${BREAKPOINT.LG}) {
    border-top: 1px solid #e9ebec;
  }

  @media (min-width: ${BREAKPOINT.MD}) {
    margin-top: -80px;
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    margin-top: 0;
  }
`;

const ArticleCategoryFilterMobile = ({
  categories,
  activeTags,
  updateActiveTags
}) => {
  const [isOpened, toggleFilterMenu] = useState(false);
  const clickFilterLink = () => {
    toggleMenu(true);
  };

  const toggleMenu = open => {
    const headerEl = document.querySelector('header.header');
    if (headerEl) {
      headerEl.classList.toggle('fixed-top');
    }
    toggleFilterMenu(open);
  };

  const clickClearButton = () => {
    updateActiveTags('all');
  };

  return (
    <CategoryFilterMobile>
      <FilterLink onClick={() => clickFilterLink()}>
        Filter by category&nbsp;
        {activeTags.length > 0 && <span>({activeTags.length})</span>}
      </FilterLink>
      {isOpened && (
        <FilterModal>
          <CloseButton onClick={() => toggleMenu(false)}>
            <CloseIcon />
          </CloseButton>
          <h5 css="font-family:roboto; font-weight: 300 !important;">
            Filter by category
          </h5>
          <ClearButton>
            <a onClick={() => clickClearButton()} className="link-noline">
              Clear
            </a>
          </ClearButton>
          <TagList>
            {categories.map((category, i) => {
              const opts = {};
              if (activeTags.indexOf(category.tags) !== -1) {
                opts.active = true;
              }
              return (
                <TagItem
                  {...opts}
                  key={i}
                  onClick={e => {
                    updateActiveTags(category.tags);
                  }}
                >
                  {category.title}
                </TagItem>
              );
            })}
          </TagList>
          <div className="mt-3 mb-5 text-center">
            <LLink
              button="tertiary"
              className="mx-auto"
              onClick={() => toggleMenu(false)}
              disableDefaultEventTracking
              css="display:inline-block;"
            >
              Show inspiration
            </LLink>
          </div>
        </FilterModal>
      )}
    </CategoryFilterMobile>
  );
};

const CategoryFilterMobile = styled.div`
  display: block;
  padding: 0 0 30px;
  text-align: center;

  @media (min-width: ${BREAKPOINT.LG}) {
    display: none;
  }
`;

const FilterLink = styled.a`
  && {
    font-weight: 400;
    color: ${COLOR.BLUE_DEEP};
    border-color: ${COLOR.BLUE_DEEP};
  }
`;

const CloseIcon = styled.div`
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='%23006AFF' fill-rule='nonzero' d='M7.596 6.265l5.99-5.99a.941.941 0 1 1 1.33 1.332l-5.989 5.99 5.99 5.989a.941.941 0 1 1-1.331 1.33l-5.99-5.989-5.99 5.99a.941.941 0 1 1-1.33-1.331l5.99-5.99-5.99-5.99a.941.941 0 1 1 1.33-1.33l5.99 5.99z'/%3E%3C/svg%3E%0A");
`;

const CloseButton = styled.div`
  display: flex;
  position: absolute;
  top: 13px;
  left: 16px;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ClearButton = styled.div`
  cursor: pointer;
  padding: 16px 10px;
  position: absolute;
  top: 0;
  right: 15px;
  && {
    > a {
      color: ${COLOR.BLUE};
    }
  }
`;

const TagList = styled.ul`
  padding: 0;
  margin: 0;
  text-align: left;
`;

const TagItem = styled.li`
  display: block;
  margin: 0;
  padding: 0;
  cursor: pointer;
  position: relative;
  padding: 16px 24px;
  color: ${COLOR.BODY};
  font-weight: 300;
  border-bottom: 1px solid ${COLOR.GREY10};

  ${props =>
    props.active &&
    css`
      && {
        color: ${COLOR.BLACK};
        font-weight: ${FONT_WEIGHT.MEDIUM};
        &:after {
          content: '';
          width: 24px;
          height: 24px;
          display: block;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3Cpath fill='%23006AFF' fill-rule='nonzero' d='M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4z'/%3E%3C/g%3E%3C/svg%3E%0A");
          position: absolute;
          top: calc(50% - 12px);
          right: 24px;
        }
      }
    `};
`;

const FilterModal = styled.div`
  width: 100%;
  height: 100%;
  background: ${COLOR.GREY6};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1031;
  h5 {
    text-align: center;
    display: block;
    font-size: 16px;
    font-weight: 500;
    color: ${COLOR.BLACK};
    background: ${COLOR.WHITE};
    padding: 12px 0;
    margin: 0;
    border-bottom: 1px solid ${COLOR.GREY10};
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.05);
  }
`;

const ArticleCategoryFilterDesktop = styled.div`
  display: none;
  align-items: center;
  height: 72px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  padding: 16px 24px;
  background-color: ${COLOR.WHITE};
  margin: 0 0 40px;
  @media (min-width: ${BREAKPOINT.LG}) {
    display: flex;
  }
`;

const StyledCard = styled(ArticleCard)`
  && {
    border: 0;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
    border-radius: 0;
    overflow: hidden;
    transition: box-shadow 150ms ease;

    .card-thumbnail {
      height: 236px;
      background-size: cover;
      background-position: 50%;
      background-repeat: no-repeat;
    }
    .card-body {
      position: relative;
      padding-top: 20px;
      text-align: center;

      &:before {
        content: '';
        background-color: ${COLOR.WHITE};
        width: 101%;
        height: 45px;
        transform: skew(0deg, -6deg);
        position: absolute;
        top: -22px;
        left: 0;
        z-index: 2;
      }
    }
    h5 {
      font-size: 20px;
      line-height: 1.5;
      margin: 0 0 10px;
      position: relative;
      z-index: 3;
    }
    a {
      color: ${COLOR.BLACK};
      text-decoration: none;
    }
    p {
      color: ${COLOR.BLACK};
      font-weight: 300;
    }

    &:hover {
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.34);
      transition: box-shadow 150ms ease;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  > span {
    margin: 0 20px 0 0;
  }
`;

const ClearCategoryButton = styled.div`
  color: ${COLOR.BLUE_DEEP};
  cursor: pointer;
  text-decoration: underline;
  padding: 8px 16px;
  margin-left: auto;
  margin-right: 0;
`;

const CategoryButton = styled.div`
  height: 40px;
  border-radius: 25px;
  margin: 0 10px 0 0;
  cursor: pointer;
  color: ${COLOR.BLACK};
  background-color: #f2f2f2;
  font-size: 16px;
  font-weight: 300;
  padding: 8px 16px;
  transition: all 150ms ease;

  &:hover {
    background-color: ${COLOR.GREY12};
    color: ${COLOR.GREY75};
    transition: all 150ms ease;
  }

  ${props =>
    props.active &&
    css`
      && {
        background: ${COLOR.BLUE};
        color: ${COLOR.WHITE};
        &:hover {
          background: ${COLOR.BLUE_DARK};
          color: ${COLOR.WHITE};
        }
      }
    `};
`;

export const pageQuery = graphql`
  query travelInspiration {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: {
          glob: "**/src/templates/travel-inspiration/articles/*.md"
        }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
    ) {
      edges {
        node {
          html
          frontmatter {
            id
            title
            description
            travelTags
          }
        }
      }
    }
  }
`;

export default TravelInspirationPage;
